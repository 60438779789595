import {ProgressBar, Table} from "react-bootstrap";
import {getProgressbarColor} from "../Pokedex";
import {getPokemonFromLivingDex} from "../../service/livingDexService";

const generations = [
    {name: "Generation 1", start: 1, end: 151},
    {name: "Generation 2", start: 152, end: 251},
    {name: "Generation 3", start: 252, end: 386},
    {name: "Generation 4", start: 387, end: 493},
    {name: "Generation 5", start: 494, end: 649},
    {name: "Generation 6", start: 650, end: 721},
    {name: "Generation 7", start: 722, end: 809},
    {name: "Generation 8", start: 810, end: 905},
    {name: "Generation 9", start: 906, end: 1025},

];

const caughtPokemonInGeneration = (start, end) => {
    let caught = 0;
    for (let i = start; i <= end; i++) {
        var pokemon = getPokemonFromLivingDex(i);
        if (pokemon !== null && pokemon === true) {
            caught++;
        }
    }
    return caught;
};

export default function GenerationOverview() {
    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Generation</th>
                <th>Caught Pokemons</th>
                <th>Progress</th>
            </tr>
            </thead>
            <tbody>
            {generations.map((generation, i) => {
                const caught = caughtPokemonInGeneration(generation.start, generation.end);
                const total = generation.end - generation.start + 1;
                const progress = (caught / total) * 100;
                const progressBarColor = getProgressbarColor(progress);
                return (
                    <tr key={i}>
                        <td>{generation.name}</td>
                        <td>{caught} / {total}</td>
                        <td>
                            <ProgressBar variant={progressBarColor} now={progress} label={`${Math.round(progress)}%`}/>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    );
}