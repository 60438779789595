import {Tab, Tabs} from "react-bootstrap";
import BoxOverview from "./overviews/BoxOverview";
import GenerationOverview from "./overviews/GenerationOverview";


export default function Overview() {
    return (
        <Tabs
            defaultActiveKey="box_overview"
            id="uncontrolled-tab-example"
            className="mb-3"
        >
            <Tab eventKey="genereation_overview" title="Generation Overview">
                <GenerationOverview/>
            </Tab>
            <Tab eventKey="box_overview" title="Box Overview">
                <BoxOverview/>
            </Tab>
            {/*<Tab eventKey="not_catched_overview" title="Not Catched Overview">*/}
            {/*    <NotCatchedOverview/>*/}
            {/*</Tab>*/}

        </Tabs>
    );
}

