import {getBoxLength, getBoxName, getProgressbarColor} from "../Pokedex";
import {Button, ProgressBar, Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {getPokemonFromLivingDex, setBoxIndexInLocalStorage} from "../../service/livingDexService";

export default function BoxOverview() {
    const navigate = useNavigate();

    let box_length = getBoxLength();

    const caughtPokemonInBox = (boxIndex) => {
        let caught = 0;
        for (let i = boxIndex * 30 + 1; i <= boxIndex * 30 + 30; i++) {
            const pokemon = getPokemonFromLivingDex(i);
            if (pokemon !== null && pokemon === true) {
                caught++;
            }
        }
        return caught;
    };

    const handleGoClick = (i) => {
        // localStorage.setItem(`boxIndex`, i);
        setBoxIndexInLocalStorage(i);
        navigate(`/pokedex`);
    };
    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Box Number</th>
                <th>Box Name</th>
                <th>Caught Pokemons</th>
                <th>Progress</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {Array.from({length: box_length}).map((_, i) => {
                const caught = caughtPokemonInBox(i);
                const progress = (caught / 30) * 100;
                const progressBarColor = getProgressbarColor(progress);
                return (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{getBoxName(i * 30)}</td>
                        <td>{caught} / 30</td>
                        <td>
                            <ProgressBar variant={progressBarColor} now={progress} label={`${Math.round(progress)}%`}/>
                        </td>
                        <td>
                            <Button variant={"primary"} onClick={() => handleGoClick(i)}>Go</Button>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    );
}