import {Button, Col, Row} from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import {useEffect, useState} from "react";
import {getPokemonFromLivingDex, savePokemonToLivingDex} from "../service/livingDexService";

export default function PokemonCard({id, reloadFunction}) {
    const [pokemon, setPokemon] = useState(null);
    const [is_catched, setIsCatched] = useState(false);

    useEffect(() => {

        fetch(`https://pokeapi.co/api/v2/pokemon/${id}`)
            .then(response => response.json())
            .then(data => setPokemon(data));

    }, [id, pokemon]);

    useEffect(() => {
        const catchStatus = getPokemonFromLivingDex(id);//localStorage.getItem(`pokemon-${id}`);
        setIsCatched(catchStatus === true);
    }, [id]);

    if (!pokemon) {
        return <div>Loading...</div>;
    }

    const handleClick = () => {
        const newCatchStatus = !is_catched;
        setIsCatched(newCatchStatus);
        savePokemonToLivingDex(id, newCatchStatus);
        if (reloadFunction) {
            reloadFunction();

        }
    }


    return (
        <>
            <Button variant="outline-secondary" style={{background: is_catched ? "lightgreen" : "transparent"}} onClick={handleClick}>
                <Row>
                    <Col>
                        {pokemon.id}

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image className={is_catched ? 'color' : 'grey'} src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${id}.png`}></Image>

                    </Col>
                </Row>
            </Button>
        </>
    );
}