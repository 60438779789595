import React, {useState} from 'react';
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap";
import {exportLivingDex, importLivingDex, loadLivingDex, resetLivingDex, setBoxIndexInLocalStorage} from "../service/livingDexService";
import {useNavigate} from "react-router-dom";

export default function DataSaving() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [importData, setImportData] = useState(null);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            setImportData(event.target.result);
        };
        reader.readAsText(file);
    };


    return (
        <>
            <h1>Import/Export</h1>
            <Row>
                <Col className={"m-2"}>
                    <Card>
                        <Card.Header>
                            LocalStorage
                            <Card.Title>
                                Reset
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Reset all data in LocalStorage
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                            <Button variant={"danger"} onClick={() => setShowModal(true)}>Reset LocalStorage</Button>
                        </Card.Footer>
                    </Card>
                </Col>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Reset</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to reset all data in LocalStorage?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => {
                            resetLivingDex();
                            setShowModal(false);
                        }}>
                            Reset
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Col className={"m-2"}>
                    <Card>
                        <Card.Header>
                            LocalStorage
                            <Card.Title>
                                Export
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {JSON.stringify(loadLivingDex())}
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                            <Button variant={"primary"} onClick={() => exportLivingDex()}>Export LocalStorage</Button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col className={"m-2"}>
                    <Card>
                        <Card.Header>
                            LocalStorage
                            <Card.Title>
                                Import
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Form.Label>Import localStorage.json</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange}/>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                            <Button variant={"success"} onClick={() => {
                                importLivingDex(importData)
                                setBoxIndexInLocalStorage(0)
                                navigate(`/pokedex`);
                            }} disabled={!importData}>Import LocalStorage</Button>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>
    );
}