import {Container} from 'react-bootstrap';

export default function Home() {
    return (

        <Container className="p-3 my-3">
            <h1>Welcome to my website!</h1>
            <h2>What is this?</h2>
            <p>
                This website is a private project. It's a Pokedex website. It was developed to facilitate the organization of Pokemon for "Pokemon Home".
            </p>

            <h2>
                How is the website structured?
            </h2>
            <p>
                The website consists of 4 pages. The first page is the homepage. This description is located there. <br/>
                The second page is the Pokedex. There you can see all Pokemon and mark them as caught.<br/>
                The third page is the overview. There you can see the boxes and their progress. Or also sorted by generation.<br/>
                The fourth page is the Import/Export. There you can export the data as JSON and also import it again. There you will also find the reset of all data.<br/>
            </p>
            <h2>How does this website work?</h2>
            <p>
                This website uses the <a href="https://pokeapi.co/" target="_blank" rel="noreferrer">PokeAPI</a> to obtain data on the Pokémon. The PokeAPI is a RESTful API and contains information about all different
                Pokémon, their types, abilities and much more.
            </p>
        </Container>
    );
}