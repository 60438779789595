const LOCALSTORAGE_KEY = 'livingDex';


export function loadLivingDex() {
    if (localStorage.getItem(LOCALSTORAGE_KEY) === null) createLivingDex();
    let livingDex = localStorage.getItem(LOCALSTORAGE_KEY);
    return livingDex ? JSON.parse(livingDex) : {};
}

export function getPokemonFromLivingDex(pokemonId) {
    let livingDex = loadLivingDex();
    return livingDex[pokemonId];
}

export function createLivingDex() {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({"boxIndex": 0}));
}

export function setBoxIndexInLocalStorage(index) {
    let livingDex = loadLivingDex();
    livingDex["boxIndex"] = index;

    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(livingDex));
}

export function getBoxIndex() {
    let livingDex = loadLivingDex();
    return livingDex["boxIndex"] ? livingDex["boxIndex"] : 0;

}

export function savePokemonToLivingDex(pokemonId, catchStatus) {
    let livingDex = loadLivingDex();
    livingDex[pokemonId] = catchStatus;
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(livingDex));
}

export function exportLivingDex() {
    let livingDex = JSON.stringify(loadLivingDex());
    const dataBlob = new Blob([livingDex], {type: "application/json"});
    const url = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.download = `${LOCALSTORAGE_KEY}.json`;
    link.href = url;
    link.click();
}

export function importLivingDex(importData) {
    if (importData) {
        const dataObj = JSON.parse(importData);
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(dataObj));
    }
}

export function resetLivingDex() {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    createLivingDex();
}