import React from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';
import Overview from "./pages/Overview";
import Pokedex from "./pages/Pokedex";
import {Container} from "react-bootstrap";
import './App.css';
import TopBar from "./components/TopBar";
import DataSaving from "./pages/DataSaving";
import Home from "./pages/Home";

function App() {
    if (!localStorage.getItem('boxIndex')) {
        localStorage.setItem('boxIndex', 0);
    }

    return (
        <div className="App">
            <HashRouter>
                <TopBar/>
                <Container>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path={"/overview"} element={<Overview/>}/>
                        <Route path={"/pokedex"} element={<Pokedex/>}/>
                        <Route path={"/data"} element={<DataSaving/>}/>
                    </Routes>
                </Container>
            </HashRouter>
        </div>
    );
}

export default App;