import React, {useEffect, useState} from 'react';
import PokemonCard from "../components/PokemonCard";
import {Button, Col, Container, Form, ProgressBar, Row} from "react-bootstrap";
import {getBoxIndex} from "../service/livingDexService";

export function getProgressbarColor(progress) {
    if (progress >= 100) {
        return "info";
    } else if (progress >= 80) {
        return "success";
    } else if (progress >= 30) {
        return "warning";
    } else {
        return "danger";
    }
}


export const pokemonAmount = () => {
    return 1025;
};
export const getBoxLength = () => {
    let pokemon_amount = pokemonAmount();
    let box_total = pokemon_amount / 30;
    return Math.ceil(box_total);

    // return 36;
}

export function getBoxName(currentIndex) {
    return `#${(currentIndex + 1).toString().padStart(4, '0')} - #${(currentIndex + 30).toString().padStart(4, '0')}`;
}


export default function Pokedex() {
    const pokemonIds = Array.from({length: pokemonAmount()}, (_, i) => i + 1);

    const [currentIndex, setCurrentIndex] = useState(getBoxIndex() * 30);
    const [amountCaught, setAmountCaught] = useState(0);


    useEffect(() => {
        caughtPokemonInBox();
    }, [currentIndex]);

    const handleNext = () => {
        if (currentIndex + 30 < pokemonAmount()) {
            setCurrentIndex(currentIndex + 30);
            localStorage.setItem(`boxIndex`, currentIndex / 30 + 1)
        }
    };

    const handlePrev = () => {
        if (currentIndex - 30 >= 0) {
            setCurrentIndex(currentIndex - 30);
            localStorage.setItem(`boxIndex`, currentIndex / 30 - 1)
        }
    };

    const handleSelect = (event) => {
        setCurrentIndex(event.target.value * 30);
    };


    const caughtPokemonInBox = () => {
        let caught = 0;
        for (let i = currentIndex + 1; i <= currentIndex + 30; i++) {
            var pokemon = localStorage.getItem(`pokemon-${i}`);
            if (pokemon !== null && pokemon === 'true') {
                caught++;
            }
        }
        setAmountCaught(caught);
        return caught;

    };
    const progress = (amountCaught / 30) * 100;

    const catchAllPokemon = () => {
        for (let i = currentIndex + 1; i <= currentIndex + 30; i++) {
            localStorage.setItem(`pokemon-${i}`, 'true');
        }
        // Aktualisieren Sie die Anzeige, um die Änderungen zu reflektieren
        caughtPokemonInBox();
        window.location.reload();
    };

    const deselectAllPokemon = () => {
        for (let i = currentIndex + 1; i <= currentIndex + 30; i++) {
            localStorage.setItem(`pokemon-${i}`, 'false');
        }
        // Aktualisieren Sie die Anzeige, um die Änderungen zu reflektieren

        caughtPokemonInBox();
        window.location.reload();

    };

    const progressbarColor = getProgressbarColor(progress);

    return (
        <>

            <h1>
                Pokedex
            </h1>


            <Container>
                <Row className="justify-content-between">
                    <Col className={"col-1"}><Button onClick={handlePrev}>{"<"}</Button></Col>
                    <Col>
                        <Form.Control as="select" onChange={handleSelect}>
                            {Array.from({length: getBoxLength()}, (_, i) => (
                                <option selected={i === getBoxIndex()} key={i} value={i}>{`Box ${i + 1}: #${(i * 30 + 1).toString().padStart(4, '0')} - #${((i + 1) * 30).toString().padStart(4, '0')}`}</option>
                            ))}
                        </Form.Control>

                    </Col>
                    <Col className={"col-1"}><Button className="float-right" onClick={handleNext}>{">"}</Button></Col>
                </Row>
                <ProgressBar className={"m-2"} variant={progressbarColor} now={progress} label={`${amountCaught}/30`}/>
                <Row className="justify-content-center">
                </Row>
                {[...Array(5)].map((_, rowIndex) => (
                    <Row key={rowIndex}>
                        {pokemonIds.slice(currentIndex + rowIndex * 6, currentIndex + rowIndex * 6 + 6).map(id => (
                            <Col key={id} className="d-flex flex-column align-items-center col-4 col-sm-4 col-md-2">
                                <PokemonCard id={id} reloadFunction={caughtPokemonInBox}/>
                            </Col>
                        ))}
                    </Row>
                ))}
                <Row className={"m-2"}>
                    <Row className={"m-2"}>
                        <Col>
                            <Button onClick={deselectAllPokemon}>Deselect all</Button>
                        </Col>
                        <Col>
                            <Button onClick={catchAllPokemon}>Select all</Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </>
    );
}